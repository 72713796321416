var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen" }, [
    _vm._m(0),
    _c("div", { staticClass: "border-b border-brown-grey" }),
    _c(
      "div",
      { staticClass: "container mx-auto mt-6" },
      [
        _c(
          "ValidationObserver",
          {
            ref: "createForm",
            staticClass: "container mx-auto mt-6",
            attrs: { tag: "div" },
          },
          [
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [_vm._v("会社区分*")]
                    ),
                  ]
                ),
                _c("fl-select", {
                  class:
                    "xl:col-span-9 lg:col-span-9 md:col-span-5 sm:col-span-4 md:mr-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    options: _vm.companyTypeProvider,
                    fieldId: "company_type",
                    required: true,
                  },
                  model: {
                    value: _vm.inputValues.company_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "company_type", $$v)
                    },
                    expression: "inputValues.company_type",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "inline-block align-bottom font-bold" },
                      [
                        _vm._v(
                          "会社名" +
                            _vm._s(_vm.requiresCompanyFields() ? "*" : "")
                        ),
                      ]
                    ),
                  ]
                ),
                _c("fl-labeled-input", {
                  class: "col-span-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    rules: { required: _vm.requiresCompanyFields(), max: 255 },
                    fieldId: "company_name",
                  },
                  model: {
                    value: _vm.inputValues.company_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "company_name", $$v)
                    },
                    expression: "inputValues.company_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "inline-block align-bottom font-bold" },
                      [
                        _vm._v(
                          " 会社名（ローマ字）" +
                            _vm._s(_vm.requiresCompanyFields() ? "*" : "") +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("fl-labeled-input", {
                  class: "col-span-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    rules: { required: _vm.requiresCompanyFields(), max: 255 },
                    fieldId: "company_name_english",
                  },
                  model: {
                    value: _vm.inputValues.company_name_english,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "company_name_english", $$v)
                    },
                    expression: "inputValues.company_name_english",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-bottom text-base font-bold",
                      },
                      [_vm._v("会社ウェブサイト")]
                    ),
                  ]
                ),
                _c("fl-labeled-input", {
                  class: "col-span-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    rules: { required: false, max: 255, url: true },
                    fieldId: "company_url",
                  },
                  model: {
                    value: _vm.inputValues.company_url,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "company_url", $$v)
                    },
                    expression: "inputValues.company_url",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right font-bold flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "inline-block align-middle text-base" },
                      [_vm._v("母国語*")]
                    ),
                  ]
                ),
                _c("fl-select", {
                  class:
                    "xl:col-span-9 lg:col-span-9 md:col-span-5 sm:col-span-4 md:mr-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    required: true,
                    options: _vm.language,
                    fieldId: "preferred_language",
                  },
                  model: {
                    value: _vm.inputValues.preferred_language,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "preferred_language", $$v)
                    },
                    expression: "inputValues.preferred_language",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "mb-8 grid grid-cols-12 h-12 sm:grid-cols-6 sm:h-auto",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 mt-4 text-right font-bold flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "inline-block align-middle text-base" },
                      [_vm._v("ご担当者名*")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-9 px-2 h-10 text-left flex sm:flex-col sm:col-span-6 sm:h-auto",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-4 mt-4 w-full mb-4" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: true, max: 255 },
                            sublabel: "姓",
                            fieldId: "last_name",
                          },
                          model: {
                            value: _vm.inputValues.last_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputValues, "last_name", $$v)
                            },
                            expression: "inputValues.last_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-4 w-full" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: true, max: 255 },
                            sublabel: "名",
                            fieldId: "first_name",
                          },
                          model: {
                            value: _vm.inputValues.first_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputValues, "first_name", $$v)
                            },
                            expression: "inputValues.first_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mb-8 grid grid-cols-12 h-12 sm:grid-cols-6 sm:h-auto",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 mt-4 text-right font-bold flex items-center justify-end sm:col-span-6 sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "inline-block align-middle text-base" },
                      [_vm._v("ご担当者名（ローマ字）")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-9 px-2 h-10 text-left flex sm:flex-col sm:col-span-6 sm:h-auto",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-4 mt-4 w-full mb-4" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: false, max: 255, english: true },
                            sublabel: "姓",
                            fieldId: "last_name_english",
                          },
                          model: {
                            value: _vm.inputValues.last_name_english,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inputValues,
                                "last_name_english",
                                $$v
                              )
                            },
                            expression: "inputValues.last_name_english",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-4 w-full" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: false, max: 255, english: true },
                            sublabel: "名",
                            fieldId: "first_name_english",
                          },
                          model: {
                            value: _vm.inputValues.first_name_english,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inputValues,
                                "first_name_english",
                                $$v
                              )
                            },
                            expression: "inputValues.first_name_english",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [
                        _vm._v(
                          " 電話番号" +
                            _vm._s(_vm.requiresCompanyFields() ? "*" : "") +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("ValidationProvider", {
                  staticClass:
                    "col-span-9 px-2 h-10 text-left flex items-center justify-start sm:col-span-5 sm:h-auto",
                  attrs: {
                    tag: "div",
                    rules: { required: _vm.requiresCompanyFields() },
                    name: "電話番号",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return [
                          _c("VuePhoneNumberInput", {
                            attrs: {
                              "default-country-code": _vm.defaultCountryCode,
                              translations: _vm.phoneFieldTranslations,
                            },
                            on: { update: _vm.getPhoneNumber },
                            model: {
                              value: _vm.inputValues.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputValues, "tel", $$v)
                              },
                              expression: "inputValues.tel",
                            },
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: errors.length,
                                  expression: "errors.length",
                                },
                              ],
                              staticClass: "text-xs text-red-600 mt-1",
                            },
                            [_vm._v(" " + _vm._s(errors[0]) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:col-span-5 sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [
                        _vm._v(" メールアドレス* "),
                        _c(
                          "span",
                          { staticClass: "hidden text-xxs sm:block" },
                          [_vm._v("メールアドレスがIDになります。")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-9 px-2 h-10 text-left flex justify-start items-center sm:flex-col sm:grid-cols-6 sm:h-auto",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-5/12 sm:w-full" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: true, max: 255 },
                            fieldId: "email",
                          },
                          model: {
                            value: _vm.inputValues.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputValues, "email", $$v)
                            },
                            expression: "inputValues.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-8 md:ml-2 text-xxs sm:hidden" },
                      [_vm._v("メールアドレスがIDになります。")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:col-span-5 sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [
                        _vm._v(" パスワード*"),
                        _c(
                          "span",
                          { staticClass: "hidden text-xxs sm:block" },
                          [_vm._v("パスワードは8文字以上でお願いします。")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-9 px-2 h-10 text-left flex justify-start items-center sm:flex-col sm:grid-cols-6 sm:h-auto",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-5/12 sm:w-full" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { required: true, min: 8 },
                            type: "password",
                            fieldId: "password",
                          },
                          model: {
                            value: _vm.inputValues.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputValues, "password", $$v)
                            },
                            expression: "inputValues.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-8 md:ml-2 text-xxs sm:hidden" },
                      [_vm._v("パスワードは8文字以上でお願いします。")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid grid-cols-12 sm:grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-3 pr-4 text-right flex items-center justify-end sm:col-span-5 sm:pl-2 sm:justify-start sm:text-left",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("commons.fields.invoice_number")) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-9 px-2 h-10 text-left flex justify-start items-center sm:flex-col sm:grid-cols-6 sm:h-auto",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-5/12 sm:w-full mb-4" },
                      [
                        _c(
                          "FlCheckbox",
                          {
                            model: {
                              value: _vm.hasInvoice,
                              callback: function ($$v) {
                                _vm.hasInvoice = $$v
                              },
                              expression: "hasInvoice",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("pages.providers.profile.has_invoice")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("fl-labeled-input", {
                  class: "col-span-9 px-2 h-10 text-left sm:h-auto",
                  attrs: {
                    sublabel: _vm.$t("pages.providers.profile.invoice_number"),
                    rules: { invoice_number: true },
                    fieldId: "invoice_number",
                    disabled: !_vm.inputValues.has_invoice,
                  },
                  model: {
                    value: _vm.inputValues.invoice_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputValues, "invoice_number", $$v)
                    },
                    expression: "inputValues.invoice_number",
                  },
                }),
              ],
              1
            ),
            _c("label", { staticClass: "mx-2 font-bold" }, [
              _vm._v("利用規約*"),
            ]),
            _c(
              "div",
              { staticClass: "mx-2 mb-14 sm:mb-7 h-96" },
              [
                _c("FlShowTac", {
                  attrs: { userType: "provider" },
                  model: {
                    value: _vm.agreed,
                    callback: function ($$v) {
                      _vm.agreed = $$v
                    },
                    expression: "agreed",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-8 grid md:grid-cols-12 grid-cols-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md:col-start-5 md:col-span-4 pr-4 col-start-2 col-span-4 sm:pl-2",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "inline-block align-middle text-base font-bold",
                      },
                      [_vm._v("プライバシーポリシー*")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md:col-start-5 md:col-span-4 px-2 h-10 col-start-2 col-span-4 sm:h-auto",
                  },
                  [
                    _c(
                      "FlCheckbox",
                      {
                        model: {
                          value: _vm.acceptPrivacyPolicy,
                          callback: function ($$v) {
                            _vm.acceptPrivacyPolicy = $$v
                          },
                          expression: "acceptPrivacyPolicy",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "font-semibold underline",
                            attrs: {
                              href: "/privacy-policy",
                              target: "_blank",
                            },
                          },
                          [_vm._v("プライバシーポリシー")]
                        ),
                        _vm._v("を読み同意します。 "),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-8 md:ml-2 text-xxs sm:hidden" },
                      [
                        _vm._v(
                          "アカウント作成にはプライバシーポリシーを読み同意が必要です。"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", [
              _c(
                "div",
                { staticClass: "w-5/12 mx-auto px-4 mb-12" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: {
                        disabled: !_vm.agreed || !_vm.acceptPrivacyPolicy,
                        classList: "px-1 py-4",
                        processing: _vm.sending,
                      },
                      on: { clickButton: _vm.create },
                    },
                    [_vm._v(" 登録 ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("NoticeModal", {
          attrs: {
            modalName: "successAdd",
            contents: "アカウントの作成が完了しました。",
            buttons: ["OK"],
          },
          on: {
            beforeClose: function ($event) {
              return _vm.redirectTop()
            },
            click: function ($event) {
              return _vm.redirectTop()
            },
          },
        }),
        _c("NoticeModal", {
          attrs: {
            modalName: "failedAdd",
            contents:
              "アカウントの作成に失敗しました。赤くなっているフィールドをご確認ください。",
            buttons: ["OK"],
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mt-8 mb-4 text-left container mx-auto tracking-widest" },
      [_c("h1", { staticClass: "sm:text-center" }, [_vm._v("登録申請")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }